import React, { Component, Fragment } from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';

import { Helmet } from "react-helmet";

import loadable from '@loadable/component'

import ReactGA from 'react-ga4';
import Cookies from 'universal-cookie';

import './App.css';

import Errors from './components/errors';

const Auth = loadable(() => import('./components/auth'));

const Dashboard = loadable(() => import('./view/dashboard'));

const Reports = loadable(() => import('./view/reports'));
const Allposts = loadable(() => import('./view/allposts'));

const Users = loadable(() => import('./view/users'));
//const About = loadable(() => import('./view/about'));
const Location = loadable(() => import('./components/location'));
const Index = loadable(() => import('./view/index'));

const Feed = loadable(() => import('./view/feed'));
const New = loadable(() => import('./view/new'));
const Privacy = loadable(() => import('./components/privacy'));
const Terms = loadable(() => import('./components/terms-conditions'));
const CookiePolicy = loadable(() => import('./components/cookie-policy'));
const Rules = loadable(() => import('./components/rules'));



const Register = loadable(() => import('./components/register'));
const Login = loadable(() => import('./components/login'));
const Password = loadable(() => import('./components/password'));

const User = loadable(() => import('./view/user'));
const Post = loadable(() => import('./view/post'));
const Edit = loadable(() => import('./view/edit'));
const Logout = loadable(() => import('./components/logout'));

const E404 = loadable(() => import('./components/404'));


const Menu  = loadable(() => import('./components/menu'));
const HashTags = loadable(() => import('./components/hashtags'));

const Filter = loadable(() => import('./components/filter'));

/*const Errors = loadable(() => import('./components/errors')); */


export default class App extends Component {
	constructor(props) {
    	super(props);
    	this.getPost = this.getPost.bind(this);
		this.getPosts = this.getPosts.bind(this);
		this.zoomChange = this.zoomChange.bind(this);
		this.menuONOFF = this.menuONOFF.bind(this);
		this.toHome = this.toHome.bind(this);
		this.cookieAgree = this.cookieAgree.bind(this);
		this.locationChange = this.locationChange.bind(this);
		this.move = this.move.bind(this);
		this.updateViewport = this.updateViewport.bind(this);
		this.postOpen = this.postOpen.bind(this);
		this.compose = this.compose.bind(this);
		this.statusChange = this.statusChange.bind(this);
		this.settingsChange = this.settingsChange.bind(this);
		this.sessionInput = this.sessionInput.bind(this);
		this.manualRefresh = this.manualRefresh.bind(this);
		this.refresh = this.refresh.bind(this);
		this.autoRefresh = this.autoRefresh.bind(this);
		this.redirect = this.redirect.bind(this);
		this.dialog = this.dialog.bind(this);
		this.filter = this.filter.bind(this);
		this.publish = this.publish.bind(this);
		this.updateTerms = this.updateTerms.bind(this);
		this.guideClose = this.guideClose.bind(this);
		this.alertClose = this.alertClose.bind(this);
		this.hashtagsONOFF = this.hashtagsONOFF.bind(this);
		this.errorUpdate = this.errorUpdate.bind(this);
		this.setError = this.setError.bind(this);
		this.styleChange = this.styleChange.bind(this);

		
		this.location = {
			latitude: 0,
	    	longitude: 0
		}
		
		this.session = {}
		this.posts = false;
		
		this.post = {
			latitude: false,
  			longitude: false,
			data: [],
			name: false,
			info: false,
			url: false,
			email: false,
			phone: false
		}
		
		this.state = {
			authorized: false,
			settings : {
		    	autorefresh: false
			},
			data : false,
			loading: false,
			hashtags: false,
			post: this.post,
			menu: false,
			hashtagsOpen: true,
			terms: false
		}
		
		this.cookie = false;

  	}
  	
	componentWillMount() {
		
		let path = this.props.location.pathname;
		
		if(path) path = path.replace(/^\s*\/*\s*|\s*\/*\s*$/gm, '');
		else path = false;

		
		if(!path.startsWith('rules') && !path.startsWith('cookies') && !path.startsWith('terms') && !path.startsWith('privacy')) {
			
			const cookies = new Cookies();
			let cookie = cookies.get('cookies');
			
			if(cookie) {
				ReactGA.initialize('G-6SMEE4PCSJ');
				this.cookie = true;
			}
		}
		
		
		if(!localStorage.getItem("latitude") || !localStorage.getItem("longitude")) {
			let places = [];
				
			places.push({ latitude: 59.437065, longitude: 24.753512 });
			//places.push({ latitude: -33.8553947, longitude: 151.2077118 });
			//places.push({ latitude: 41.3869399, longitude: 2.1697735 });
			//places.push({ latitude: 48.8585179, longitude: 2.3377258 });
			//places.push({ latitude: -41.2961178, longitude: 174.7939921 });
			
			let randPlace = Math.floor(Math.random() * (places.length - 1) );

			if(this.cookie) {

				localStorage.setItem("latitude", places[randPlace].latitude);
				localStorage.setItem("longitude", places[randPlace].longitude);
				localStorage.setItem("zoom", this.state.zoom);
			}
			
			this.location.latitude = places[randPlace].latitude;
			this.location.longitude = places[randPlace].longitude;

			
		} else {
			
			this.location.latitude = parseFloat(localStorage.getItem("latitude"));
			this.location.longitude = parseFloat(localStorage.getItem("longitude"));
			
		}
		
		if(path.startsWith('message/')) {
			

			let id = path.match(/^message\/(\d+)/);
			
			if(id && id.length && id[1].length) id = id[1];

			let data = false;
			
			if(id && !isNaN(id)) {
				
				this.getPost(id);
				this.setState({ loading: true });
				// return false? start waiting!!!
				
			}
			
		} else if(path.startsWith('dashboard')) {
			
		} else if(path.startsWith('at')) {

			let coordinates = path.replace("at", "");
			coordinates = coordinates.replace("/", "");
			coordinates = coordinates.match(/^(\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)$/g);
			
			if(coordinates) {

				let c  = coordinates[0].split(',');
				let latitude = c[0];
				let longitude = c[1];
				
				this.location = {
					latitude: parseFloat(latitude),
					longitude: parseFloat(longitude)
				}
				
				if(this.cookie) {
				
					localStorage.setItem("latitude", latitude);
					localStorage.setItem("longitude", longitude);
					
				}
			}
		}
		
			
		if(!localStorage.getItem("zoom") && this.cookie) {
			
			localStorage.setItem("zoom", this.state.zoom);
			
		}
		
		//window.addEventListener("resize", this.updateViewport);
		window.visualViewport.addEventListener("resize", this.updateViewport);
		
		let view = window.visualViewport;
		let viewHeight = view.height;
		let viewport = { height: viewHeight, width: window.innerWidth }
		
		
		let post = false;
		
		if(path.startsWith('add/')) {

			post = {
				latitude: localStorage.getItem("latitude"),
				longitude: localStorage.getItem("longitude")
			}
		}

		if(this.state.autorefresh === true) {
			
			let AR = setInterval(() => {
	
				this.autoRefresh();
	
			}, 6000);
			
			window.autorefresh = AR;
		}		


		
		if(localStorage.getItem("authorized")) {
			
//			if(path.startsWith('add/')) {
//
//				post = {
//					latitude: localStorage.getItem("latitude"),
//					longitude: localStorage.getItem("longitude")
//				}
//			}

			this.setState({
				authorized: true,
				location: {
					latitude: this.location.latitude,
					longitude: this.location.longitude
				},
				settings : {
					autorefresh: true
				},
				user: {
					id: localStorage.getItem("id"),
					hash: localStorage.getItem("hash"),
					username: localStorage.getItem("username"),
				},
				path: path,
				viewport: viewport,
				post: post
			});

		} else {

			this.setState({ authorized: false, user: false, path: path, viewport: viewport });

		}

	}

	componentDidMount() {
		
		//if(localStorage.getItem("authorized") && this.cookie && (this.props.location.pathname === '/' || this.props.location.pathname === '/messages')) { this.refresh(); }
		this.refresh();

	}
	
//	componentDidUpdate() {
//
//		const cookies = new Cookies();
//		let cookie = cookies.get('agreement');
//		
//		if(!cookie && this.state.terms === false) {
//			
//			this.setState({ terms: true }); 
//			
//		}
//	}
	
	componentWillReceiveProps(nextProps) {
		
		if(this.cookie) {
			
			//ReactGA.initialize('G-6SMEE4PCSJ');

//			localStorage.setItem("latitude", this.location.latitude ? this.location.latitude : 0 );
//			localStorage.setItem("longitude", this.location.longitude ? this.location.longitude : 0);
		}
		
		if(nextProps.location.status && nextProps.location.status === 'added') {
			
			this.refresh();
			
		}
		if(this.props.location.pathname === this.state.redirect) {
			
			this.setState({ redirect: false });
			
		}
		
//		if(this.props.location.pathname === '/about' && nextProps.location.pathname === '/') {
//
//			this.setState({ redirect: '/rules' });
//			
//		}
		
		let path = nextProps.location.pathname.replace(/^\s*\/*\s*|\s*\/*\s*$/gm, '');
		
		if(nextProps.data && nextProps.data.menu && nextProps.data.menu !== this.state.menu) {
			
			this.setState({ menu: nextProps.data.menu });
			
		} else if(this.state.menu === true) {
			
			this.setState({ menu: false });
			
		}
		
		if(path !== this.state.path && this.state.error !== false) {
			
			if(window.errorTimer) {
				
				clearTimeout(window.errorTimer);
				
			}
				
			this.setState({ error: false });
			
		}
		
		if(path !== this.state.path) {

			this.setState({ path: path });

		}
		
		if(path.startsWith('message/')) {
			
			let id = path.match(/^message\/(\d+)/);

			if(id.length && id[1].length) id = id[1];
			
			let data = false;
			
			if(id && !isNaN(id) && id) {

				this.getPost(id);
				
			}
			
			
		} else if(path.startsWith("at")) {
			
			let coordinates = path.replace("at", "");
			coordinates = coordinates.replace("/", "");
			coordinates = coordinates.match(/^(\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)$/g);
			
			if(coordinates) {

				let c  = coordinates[0].split(',');
				let latitude = parseFloat(c[0]);
				let longitude = parseFloat(c[1]);
				
				if(localStorage.getItem("latitude") !== latitude || localStorage.getItem("longitude") !== longitude) {
					localStorage.setItem("latitude", latitude);
					localStorage.setItem("longitude", longitude);
	
//					if(this.state.settings.autorefresh === true) this.refresh();
					//this.refresh();
					
					this.location.latitude = latitude;
					this.location.longitude = longitude;
					
					this.setState({ location: this.location });
				}
			}
			
		} else if(path.startsWith("add/location")) {

			let post = {
				latitude: localStorage.getItem("latitude"),
				longitude: localStorage.getItem("longitude")
			}
			
			this.setState({post: post });
		}
  	}
  	
  	componentWillUnmount() {
  		
  		window.removeEventListener("resize", this.updateViewport);
  		
  	}

	
		
	locationChange(data) {

		localStorage.setItem("latitude", data.latitude);
		localStorage.setItem("longitude", data.longitude)
		
		this.location.latitude = data.latitude;
		this.location.longitude = data.longitude;
		
		//this.getPosts();
		
		this.setState({
			move: true,
			loading: false,
			location: this.location
		});
	}
	
	toHome() {
		
		if(this.state.error) this.setState({ error: false });
		
		if(this.cookie) ReactGA.event({
			category: 'Action',
			action: 'Return home'
		});
		
		window.location.change('/');
	}
	
	errorUpdate() {

		this.setState({ error: false });
	}
	
	move(data) {

		if(this.state.settings.autorefresh === false) {

			this.setState({
				move: true,
				loading: false

			});
		}
		
	}
	
	dialog(data) {

		this.setState({ dialog : data })
	
	}
	
	updateViewport(data) {

		//this.setState({ viewport: { height: window.visualViewport.height, width: window.innerWidth } });
	
	}
	updateTerms(data) {
		
		let redirect = false;
		
		if(this.props.location.state && this.props.location.state.dest && this.props.location.state.dest !== this.props.location.pathname) {
			
			redirect = this.props.location.state.dest;
			
		}

		this.setState({ terms: data, redirect : redirect });
		
	
	}
	
	zoomChange(data) {
	
		localStorage.setItem("zoom", data.zoom);
	
	}
	
	
	menuONOFF(data) {

		this.setState({ menu : data.status });
		//this.setState({ menu : data.status, redirect : data.redirect });
	
	}

	compose(data) {
		
		if(data.post && (data.post.latitude && data.post.latitude)) {
		
//			let latitude = data.post.latitude;
//			let longitude = data.post.longitude;
//			
//			this.location = {
//				latitude: latitude,
//				longitude: longitude
//			}
		}
		this.setState({ 
			post: data.post,
			locked: true,
			move: false,
//			location: this.location
		});
		
	}
	
	guideClose(data) {

		switch(data.target.value) {
			case 'check':
				localStorage.setItem("guide-check", true);
			break;
			case 'browse':
				localStorage.setItem("guide-browse", true);
			break;
			case 'map':
				localStorage.setItem("guide-map", true);
			break;
			case 'offscreen':
				localStorage.setItem("guide-offscreen", true);
			break;
			default:
			
			break;
		}
		this.setState({
			guide: false
		});
		//data.target.closest('.status').remove();
		
	}
	
	alertClose() {
		
		this.setState({ alert: false });
		
		
	}
	styleChange(e) {
		
		this.setState({ mapstyle: e });
		
		
	}
	
	postOpen(data) {
		
		if(data) {

			let newdata = [];
			let newposts = {};
			
			if(this.state.data.length) {
				newdata = this.state.data;
				newposts = this.state.posts
				
				if(this.state.posts[data.id].length) {

					newdata[this.state.posts[data.id].index] = data;
					
				}
					
			} else {

				newdata[0] = data;
				newposts[data.id] = { id: data.id, index: 0 }
				
			} 
			this.posts = newposts;
			
			this.setState({ 
				post: data,
				data: newdata,
				posts: newposts
			});
			
		}
	}
	
	filter(data) {
	    let hashtags = this.state.hashtags;
	    this.posts = {};
	    let newtags = {};
	
	    if (data.length && this.state.data) {
	
	        // Loome filterobjekti, kus võti on hashtag ja väärtus on selle hashtagiga seostatud postituste IDd
	        let filterObj = {};
	        for (let filterword of data) {
	            if (hashtags[filterword]) {
	                filterObj[filterword] = hashtags[filterword];
	            }
	        }
	
	        // Võtame kõik märksõnad, mida kasutaja otsib
	        let keys = Object.keys(filterObj);
	
	        let ids = [];
	        if (keys.length > 0) {
	            // Kasutame esimest hashtag'i IDde lähtepunktina
	            ids = [...filterObj[keys[0]]];
	
	            // Intersect - leiame nende postituste ID'd, mis on olemas kõigis järgneva hashtag'i loendites
	            for (let i = 1; i < keys.length; i++) {
	                let currentTagIds = filterObj[keys[i]];
	                ids = ids.filter(id => currentTagIds.indexOf(id) !== -1);
	            }
	        }
	
	        let possible = [];
	        for (let idVal of ids) {
	            let postData = this.state.posts[idVal];
	
	            if (postData && (postData.index || postData.index === 0)) {
	                this.posts[idVal] = { id: idVal, index: postData.index };
	                let posttags = this.state.data[postData.index].hashtags.slice();
	                // Lisame kõik leitud hashtagid "possible" massiivi
	                possible = possible.concat(posttags);
	                // Eemaldame duplikaadid
	                possible = possible.filter((value, index, self) => self.indexOf(value) === index);
	            }
	
	            // Valime välja ainult need uued hashtagid (newtags),
	            // mis esinevad kõigis ids massiivis olevates postitustes
	            if (possible.length) {
	                for (let ptag of possible) {
	                    if (hashtags[ptag]) {
	                        newtags[ptag] = hashtags[ptag];
	                    }
	                }
	            }
	        }
	
	        // Nüüd filtreerime newtags nii, et alles jääksid vaid need id-d, mis on ids massiivis
	        let statetags = {};
	        Object.keys(newtags).forEach(tag => {
	            let filteredIds = newtags[tag].filter(tagId => ids.indexOf(tagId) !== -1);
	            if (filteredIds.length > 0) {
	                statetags[tag] = filteredIds;
	            }
	        });
	
	        newtags = statetags;
	
	    } else {
	        this.posts = this.state.posts;
	    }
	
	    this.setState({ filter: data, filtertags: newtags });
	}

	
	publish(data) {

		if(data === true) {
			this.setState({ 
				post: false,
				alert: 'post-added'
			}, this.refresh());
		}
	}
	
	hashtagsONOFF() {

		let toggle = false;
		if(this.state.hashtagsOpen === false) toggle = true;
		
		this.setState({ hashtagsOpen : toggle });
		
	}
	redirect(url) {
	
		this.setState({ redirect: url });
		
	}
	
	statusChange(data) {

		if(data.id && data.hash) {
			
			localStorage.setItem("authorized", true);
			localStorage.setItem("id", data.id);
			localStorage.setItem("hash", data.hash);
			localStorage.setItem("username", data.username);

			this.setState({
				authorized: true,
				user: data,
				alert: 'logged-in'
			});
			
		} else if(data.status === "GOT-DATA") {
			
			let destination = data.destination ? data.destination : false;
			this.setState({
				authorized: false,
				destination: destination,
				status: data.status
			});
			
		} else if(data.destination === false) {

			this.setState({
				destination: false
			});

		} else {

			localStorage.removeItem("authorized");
			localStorage.removeItem("id");
			localStorage.removeItem("hash");
			localStorage.removeItem("username");
			localStorage.removeItem("autorefresh");

			const error = data.error ? data.error : false;
			const destination = data.destination ? data.destination : false;
			const status = data.status ? data.status : false;

			this.setState({
				authorized: false,
				user: false,
				error: error,
				destination: destination,
				status: status,
				alert: 'logged-out'
			});
		}
	}

	settingsChange(data) {
	
		localStorage.setItem("autorefresh", data.autorefresh);
		this.setState({ settings: data });
		
		if(data.autorefresh === true) {
			
			if(window.autorefresh) clearInterval(window.autorefresh);
			
			let AR = setInterval(() => {
					
					this.autoRefresh();
					
			}, 60000);
				
			window.autorefresh = AR;
			this.autoRefresh();

		}
		
		else if(data.autorefresh === false && window.autorefresh) clearInterval(window.autorefresh);
		
	}
	
	setError(data) {

		if(this.state.error !== data.target.value) this.setState({ error: data.target.value });
		
	}
	
	sessionInput(data) {
		
		this.session = data;
	}
	
	cookieAgree() {
		
		const cookies = new Cookies();
		
		let date = new Date();
		date.setTime(date.getTime() + (1000*60*60*24*180));
		cookies.set('cookies', true, { path: '/', expires: date, maxAge: 60*60*24*180, secure: true, sameSite: 'None' });
		
		this.setState({
			cookies: true
		})
	}

	refresh() {
			
		this.getPosts();

	}
	
	autoRefresh() {
		
		if(this.props.location && this.props.location.pathname) {
			
			let pathname = this.props.location.pathname;

			if(!pathname.startsWith('/add/') && !pathname.startsWith('/edit/') && !pathname.startsWith('/message/')) {

				this.refresh();
				
			}
		}
	}
	
	manualRefresh(e) {
		
		if(window.autorefresh) {
			
			clearInterval(window.autorefresh);
			
			let AR = setInterval(() => {
					
					this.refresh();
					
			}, 60000);
				
			window.autorefresh = AR;
		}
		
		
		if(window.manualrefresh) {

			return false;
			
		} else {
			
			window.manualrefresh = true;
		}
		
		let latitude = localStorage.getItem("latitude") ? parseFloat(localStorage.getItem("latitude")) : this.location.latitude;
		let longitude = localStorage.getItem("longitude") ? parseFloat(localStorage.getItem("longitude")) : this.location.longitude;
		
		this.location.latitude = latitude;
		this.location.longitude = longitude;
		
		this.refresh();
	}
	
	
	
	getPost(id) {

		
		let latitude = this.location.latitude ? this.location.latitude : false;
		let longitude = this.location.longitude ? this.location.longitude : false;
		
		this.setState({ loading: true });
		
		fetch('https://atround.com/services/post.php', {
			method: "POST",
			headers: {
				"Accept": "application/json",
			},
			body: JSON.stringify({ id : parseFloat(id), longitude : longitude, latitude : latitude })
		})
		.then(res => res.json())
		.then(
			(result) => {

				let data = result.data;

				if(data) {
					
					if(data.post) {
						
						let rows = data.post.split("\n");
						
						rows = rows.map((row, i) => {
								
								if(row.slice(0, 3) === "<p>") {

									return <p data-type="p" key={ i }>{ row.replace(/<p>|<\/p>/gi, "") }</p>

								} else if(row.slice(0, 10) === "<img src=\"") {
									// !!!!!!!!!!!!!!!!!!!!!!!!
									return <div data-type="img" key={ i }><img src={row.replace(/<img src="|" \/>|"\/>/gi, "")} alt="User" /></div>

								} else {

									return <p key={ i }>{ row }</p>;

								}

							});

						
						data.data = rows;
						

					} else {

						data.data = 0;

					}
					
					delete data.post;


					// move map to the location
//					if(data.latitude && data.longitude) {
//
//						localStorage.setItem("latitude", data.latitude);
//						localStorage.setItem("longitude", data.longitude);
//						
//						this.location = {
//							latitude: parseFloat(data.latitude),
//							longitude: parseFloat(data.longitude)
//						}
//					} 
					
					this.setState({ post: data, loading: 'finished' });
					
					if(this.cookie) ReactGA.event({
						category: 'Response',
						action: 'Has post'
					});


				} else {

					data = false;
					
					if(this.cookie) ReactGA.event({
						category: 'Response',
						action: 'No post'
					});
					
					this.setState({
						post: data,
						loading: 'finished'
					});

				}
			},
			
			(error) => {
				this.setState({ error: 'NO-CONN', loading: 'finished' });
			}
		)
	}	
	
	
	
	getPosts() {
		
		let checkedLocation = {};
		checkedLocation.latitude = this.location.latitude;
		checkedLocation.longitude = this.location.longitude;

		this.setState({ loading: true, move: false }, () => {
			
			let type = false;

			if(this.props.location.pathname.startsWith('/explore')) type = 1;

			fetch('https://atround.com/services/posts.php', {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify({ latitude: this.location.latitude, longitude: this.location.longitude, type: type })
			})
			.then(res => res.json())
			.then(
				(result) => {
	
					let data = result.data;
					let posts = 0;
					let error = false;
					let hashtags = {};
					
					if(data) {
						
						let newData = {};
						data.map((post, index) => {
	
							if(post.post) {
								
								let rows = post.post.split("\n");
								post.hashtags = [];
								
								rows = rows.map((row, i) => {
	
									if(row.slice(0, 3) === "<p>") {
										
										let text = row.replace(/<p>|<\/p>/gi, "")
										let tags = text.match(/#[A-Za-z0-9-\u00A1-\uFFFF]+/gi);
										
										if(tags) {
											
											for(let i = 0; i < tags.length; i++) {
												
												let tag = tags[i].replace('#', '');
	
												if(hashtags[tag]) {
													
													if(!hashtags[tag].includes(post.id)) hashtags[tag].push(post.id);
													
												} else {
													
													hashtags[tag] = [];
													hashtags[tag].push(post.id); 
												}
												
												post.hashtags.push(tag);
												
												//register only 5 hashtags
												if(i > 3) break;
												
											}
										}
										
										return <p data-type="p" key={ i }>{ text }</p>
	
									} else if(row.slice(0, 10) === "<img src=\"") {
										// !!!!!!!!!!!!!!!!!!!!!!!!
										return <div data-type="img" key={ i }><img src={row.replace(/<img src="|" \/>|"\/>/gi, "")} alt="User content" /></div>
	
									}  else {
	
										return <p key={ i }>{ row }</p>;
	
									}
	
								});
	
								post.data = rows;
								
								if(this.cookie) ReactGA.event({
									category: 'Check',
									action: 'Has posts'
								});
	
							} else {
	
								post.data = false;
								
								if(this.cookie) ReactGA.event({
									category: 'Check',
									action: 'No posts'
								});
	
							}
							
							
							newData[post.id] = { id: post.id , index: index };
							return post;
	
						});
						
						posts = newData;
						error = 'HAS-POST';
	
					} else {
	
						data = 0;
						posts = 0;
						error = 'NO-POST';
						
						if(this.cookie) ReactGA.event({
							category: 'Check',
							action: 'No posts'
						});
	
					}
					
					
					let updatedPost = false;
					
					if(this.state.post) {
					
						if(this.state.post.id && data && posts && posts[this.state.post.id]) {
						
							updatedPost = data[posts[this.state.post.id].index];

							
						}
						
					}
					
					this.posts = posts;
					this.setState({
						data: data,
						hashtags: hashtags,
						loading: 'finished',
						posts: posts,
						move: false,
						post: updatedPost ? updatedPost : this.state.post,
						checkedLocation : checkedLocation,
						refreshed: true,
						error: error
					}, () => {
						
						if(this.state.filter) this.filter(this.state.filter);
						
					});
				},
				(error) => {
					
					this.setState({ error: 'NO-CONN' });
					
				}
			)
			
			if(window.manualrefresh) {
				
				let MR = setInterval(() => {

					clearInterval(window.manualrefresh);
					window.manualrefresh = false;
						
				}, 3000);
				
				window.manualrefresh = MR;
			}
		});
	}
	

	render() {
		
		if(this.state.redirect && this.state.redirect !== this.props.location.pathname) {

			return <Redirect to={ this.state.redirect } />;
			
		}

		if(this.state.cookies === true) {
			
			if(!localStorage.getItem("latitude")) localStorage.setItem("latitude", 59.437065);
			if(!localStorage.getItem("longitude")) localStorage.setItem("longitude", 24.753512);

		}
		
		const cookies = new Cookies();
		let agreement = cookies.get('agreement');
		let cookie = cookies.get('cookies');
		
		if(cookie === "true") cookie = true;
		else cookie = false;
		
		
		if(!agreement) {

			if((this.props.location && (
				this.props.location.pathname !== '/cookies' && 
				this.props.location.pathname !== '/terms' && 
				this.props.location.pathname !== '/privacy' && 
				this.props.location.pathname !== '/rules'))) {
				
					return <Redirect to={{ pathname:  '/rules', state: { dest: this.props.location.pathname }} } />;

			}
		}

		if(cookie) {
			ReactGA.initialize('G-6SMEE4PCSJ');
		}
 
		let pages = ['dashboard', 'reports', 'allposts', 'users', 'about', 'messages', 'location', 'privacy', 'terms', 'cookies', 'rules', 'register', 'login', 'user', 'settings', 'logout', 'password', 'add/message', 'add/location', 'add/profile', 'add/upt', 'add/register', 'add', 'report', 'edit', 'delete', 'message', 'places', 'explore', 'at', ''];
		let page = 'e404';
		let slug = 'e404';
		let back = false;
		let menu = false;
		
		
		for(let i = 0; i < pages.length; i++) {
			
			if(this.state.path.startsWith(pages[i])) {

				if(pages[i] === '') {
					
					if(this.state.path.length === 0) {
						page = pages[i];
						i = pages.length;
					}
					
				} else if(pages[i] === 'edit') {
						
					let path = this.state.path;
						
					if(path.endsWith("/message")) page = "edit/message";
					else if(path.endsWith("/profile")) page = "edit/profile";
					else if(path.endsWith("/upt")) page = "edit/upt";
					else page = "edit/location";
					i = pages.length;
						
				} else if(this.state.path === pages[i]) {
					
					page = pages[i];
					i = pages.length;
					
				} else if(this.state.path.startsWith('message/')) {
					
					page = 'message';
					i = pages.length;
					
				} else if(this.state.path.startsWith('at')) {
					
					page = 'at';
					i = pages.length;
					
					let coordinates = this.state.path.replace("at", "");
					coordinates = coordinates.replace("/", "");
					coordinates = coordinates.match(/^(\-?\d+(\.\d+)?),(\-?\d+(\.\d+)?)$/g);
					
					if(coordinates) {
						
						let c  = coordinates[0].split(',');
						let latitude = c[0];
						let longitude = c[1];
						
						this.location = {
							latitude: parseFloat(latitude),
							longitude: parseFloat(longitude)
						}
						
						localStorage.setItem("latitude", latitude);
						localStorage.setItem("longitude", longitude);
						
					}
				}
			}
		}

		let zoom = parseInt(localStorage.getItem("zoom"));
		
		//let posts = this.state.posts ? this.state.posts : this.state.data;
		let messages = false;
		let hasHastags = false;
		let filter = false;
		let title = "Post public messages to reach people around.";
		let description = "A free web service to post public messages at locations to reach people currently nearby.";

		switch(page) {
			
			case '':
			case 'at':

				//slug = 'at';
				
				title = "Browse for messages. atRound.com";
				
				messages = this.state.data;
				
				slug = 'index';
				hasHastags = true;
				filter = true;
				
				if(cookie === false) {
				
					messages = 'rules';
					
				} else if(messages === false) {
				
					messages = 'virgin';
					
				} else if(messages === 0) {
				
					messages = 'no-posts';
					
				} else {
				
					messages = 'with-posts';
				}
				
				if(this.state.move === true) {
					
					messages = messages +  ' new';
					
				}
				
				if(this.state.loading === true) {
					
					messages = messages +  ' loading';
					
				}
				menu = true;
				
			break;
			case 'explore':

				//slug = 'at';
				
				title = "Check places around. atRound.com";
				
				messages = this.state.data;
				
				slug = 'explore';
				hasHastags = true;
				filter = true;
				
				if(cookie === false) {
				
					messages = 'rules';
					
				} else if(messages === false) {
				
					messages = 'virgin';
					
				} else if(messages === 0) {
				
					messages = 'no-posts';
					
				} else {
				
					messages = 'with-posts';
				}
				
				if(this.state.move === true) {
					
					messages = messages +  ' new';
					
				}
				
				if(this.state.loading === true) {
					
					messages = messages +  ' loading';
					
				}
				menu = true;
				
			break;
			case 'dashboard':
				
				title = "Dashboard for atRound.com";
				if(this.state.authorized === false) {
					
					slug = 'e404';
					
				} else {
					
					slug = 'dashboard';
					
				}
				menu = true;
				
				
			break;
			case 'reports':
				
				title = "Reported messages. atRound.com";
				if(this.state.authorized === false) {
					
					slug = 'e404';
					
				} else {
					
					slug = 'reports';
				}
				menu = false;
				
			break;
			case 'allposts':
				
				title = "All messages. atRound.com";
				if(this.state.authorized === false) {
					
					slug = 'e404';
					
				} else {
					
					slug = 'allposts';
				}
				menu = false;
				
			break;
			case 'users':
				
				title = "Users at atRound.com";
				if(this.state.authorized === false) {
					
					slug = 'e404';
					
				} else {
					
					slug = 'users';
				}
				menu = false;
				
			break;
			case 'messages':
				
				title = "Browse for messages. atRound.com";
				messages = this.state.data;
				slug = 'posts';
				hasHastags = true;
				filter = true;
				
				if(messages === false) {
					
					messages = 'virgin';
					
				} else if(messages === 0) {
				
					messages = 'no-posts';
					
				} else {
				
					messages = 'with-posts';
				}
				
				if(this.state.move === true) {
					
					messages = messages +  ' new';
					
				}
				
				if(this.state.loading === true) {
					
					messages = messages +  ' loading';
					
				}

				menu = true;

			break;
			case 'places':
				
				title = "Browse for places around you. atRound.com";
				messages = this.state.data;
				slug = 'posts';
				hasHastags = true;
				filter = true;
				
				if(messages === false) {
					
					messages = 'virgin';
					
				} else if(messages === 0) {
				
					messages = 'no-posts';
					
				} else {
				
					messages = 'with-posts';
				}
				
				if(this.state.move === true) {
					
					messages = messages +  ' new';
					
				}
				
				if(this.state.loading === true) {
					
					messages = messages +  ' loading';
					
				}

				menu = true;

			break;
			case 'location':
				
				title = "Browse for messages. atRound.com";
				slug = 'location';
				hasHastags = true;
				filter = true;
				menu = true;
				
			break;
			case 'add/message':
			case 'edit/message':
				
				title = "Add new message to atRound.com";
				slug = 'add-post';
				menu = false;
				
			break;
			case 'add':
			case 'add/location':
			case 'edit/location':
				

				title = "Choose a location for the message";
				//zoom = 15;
				slug = 'add-location';
				menu = false;
				
			break;
			
			case 'add/profile':
			case 'edit/profile':
				
				title = "Add your contact data, if you want";
				slug = 'add-profile';
				menu = false;
				
			break;
			
			case 'add/register':

				title = "Register";
				slug = 'add-register';
				menu = false;
			
			break;
				
			case 'add/upt':
			case 'edit/upt':

				title = "Choose disappear time";
				slug = 'add-upt';
				menu = false;
				
			break;
			case 'message':
				
				title = "A message at atRound.com";
				hasHastags = true;
				filter = false;
				menu = false;
				
				slug = 'post';
				
				
				
				if(this.state.post === false) {
					
					messages = 'no-message';
					
				}
				
				if(window.feedView && window.feedView === 'explore') {
					
					back = <Link id="close" title="Close message" to="/explore">Close</Link>;
					
				} else if(window.feedView && window.feedView === 'places') {
					
					back = <Link id="close" title="Close message" to="/places">Close</Link>;
					
				}  else if(window.feedView && window.feedView === 'feed') {
					
					back = <Link id="close" title="Close message" to="/messages">Close</Link>;
					
				}  else {
					
					back = <Link id="close" title="Close message" to="/">Close</Link>;
					
				}
				
			break;
			case 'login':
				
				title = "Login to atRound.com";
				slug = 'login';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			case 'logout':
				
				title = "Log out from atRound.com";
				slug = 'logout';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			case 'register':
				
				title = "Register an account for atRound.com";
				slug = 'register';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			case 'password':
				
				title = "Choose a password for atRound.com";
				slug = 'password';
				back = <Link id="close" title="Go to main view" to="/at">Back</Link>;
							
			break;
			case 'privacy':
				
				title = "Privacy Policy for atRound.com";
				slug = 'privacy';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			case 'terms':
				
				title = "Terms and Conditions for atRound.com";
				slug = 'terms';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			case 'cookies':
				
				title = "Cookie agreement for atRound.com";
				slug = 'cookies';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			case 'rules':
				
				title = "Rules atRound.com";
				slug = 'rules';
				back = false;
							
			break;
			case 'user':
				
				title = "Users atRound.com";
				slug = 'user';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			case 'settings':
				
				title = "Settings for atRound.com";
				slug = 'settings';
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
							
			break;
			default:
				
				slug = page.replace("/", "-");
				back = <Link id="close" title="Go to main view" to="/">Back</Link>;
				
			break;
		}

		let menuClass = this.state.menu === true ? 'menu-on ' : '';
		let messagesClass = messages === false ?  '' : messages;
		
		let mapStyle = this.state.path === '' || this.state.path === 'add/location' ? true : false;
		let mapStyleClass = mapStyle && window.map && window.map.getMapTypeId() === 'hybrid' ? ' map-hybrid' : '';
		
		let bodyClass = menuClass + "" + messagesClass + mapStyleClass;
		
		let loading = false;
		
		switch(this.state.loading) {
			case true:
				loading = "active";
			break;
			case "finished":
				loading = "idle";
			break;
			default:
				loading = "";
			break;
		}
		
		let statusAlert = false;
		let statusAction = false;
		
		if(this.state.alert) {
			
			let autoclose = false;
			let statusTimeout = 6000;
			
			switch(this.state.alert) {
				
				case 'post-added':
					
					statusAlert = <p>Message is posted.</p>;
					statusAction = <button type="button" onClick={ this.alertClose }>All good!</button>;
					autoclose = true;
					
				
				break;
				case 'no-post':
					
					statusAlert = <p>No message</p>;
					statusAction = <button type="button" onClick={ this.alertClose }>Oh, I see.</button>;
					autoclose = true;
				
				break;	
				case 'logged-out':
					
					//statusAlert = <p>You are logged out now. See you!</p>;
					//statusAction = <button type="button" onClick={ this.alertClose }>See you!</button>;
					//autoclose = true;
				
				break;
				case 'logged-in':	
					
					//statusAlert = <p>Welcome! You are logged in now.</p>;
					//statusAction = <button type="button" onClick={ this.alertClose }>Sweet, thanks!</button>;
					//autoclose = true;
					
				break;
				default:
					
					statusAlert = this.props.location.state;
									
				break;
			}
			
			if(autoclose === true && this.state.alert) {
				
				let timeout = 6000;
				timeout = statusTimeout ? statusTimeout : timeout;
				
				if(window.alertTimer) {
					clearTimeout(window.alertTimer);
				}
				
				window.alertTimer = setTimeout(
					function() {
						this.setState({ alert: false });
						clearTimeout(window.alertTimer);
					}
					.bind(this),
					timeout
				);
			}
		}
		
		return (
			
			<div id={ slug } className={ bodyClass }>
				
				<Helmet>
					<title>{ title }</title>
					<meta name="description" content={ description }></meta>
					<link rel="canonical" href={ "http://atround.com/" + slug } />
				</Helmet>
				
				<div id="title"><a href="https://www.atround.com" onClick={ this.toHome } title="atRound.com">atRound</a></div>
				
				{ menu === true ? <Menu data={ this.state } status={ this.state.loading } location={ this.location } onRefresh={ this.refresh } settings={ this.state.settings } onSetChange={ this.settingsChange } onToggle = { this.menuONOFF } /> : false }
				
				{ cookie === true && (page === "" || page === "at" || page === "explore" || page === "messages" || page === "places" || page === "location" || page === "message") ?
					
					<div id="actions">
					
						{ this.posts && Object.keys(this.posts).length ? <Link to="/messages" id="message-counter"><span>{ Object.keys(this.posts).length }</span></Link> : false}
						
						
						{ page === "explore" || page === "places" ?
							
							<Fragment>
								<Link to="/explore" id="to-map" className={ page === "explore" || page === "" ? "active" : "" } title="View places on the map">Map</Link>
								<button id="refresh" className={ loading } onClick={ this.manualRefresh } title="Check for messages">Check</button>
								<Link to="/places" id="to-feed" className={ page === "places" ? "active" : "" } title="View places in a list">List</Link>
							</Fragment>
							
						:
							<Fragment>
								{ page === "messages" ? 
									<Link to="/" id="to-map" title="View messages on the map">Map</Link> 
								
								: 
									<Link to="/messages" id="to-feed" title="View messages in a list">List</Link>
								}

								<button id="refresh" className={ loading } onClick={ this.manualRefresh } title="Check for messages">Check</button>
							</Fragment>
							
						}
						
						<Link id="add-new" to="/add/" className={ page.startsWith("add/") ? "active" : "" } title="Add new message">Add</Link>
						
					</div>
					
				: false }
				
				{ back  ? back : false }
				
				
				{ agreement === "true" ?
						
					<Fragment>
						
						{ (hasHastags && filter === true) && this.state.hashtagsOpen === true ?
						<div id="search">
							{ filter === true && cookie === true && this.state.filter && this.state.filter.length ? <Filter data={ this.state } location={ this.location } onRefresh={ this.refresh } settings={ this.state.settings } filter={ this.state.filter } hashtags={ this.state.filter && this.state.filter.length ? this.state.filtertags : this.state.hashtags } onFilter={ (data) => { this.filter(data) } } /> : false }
						
							{ hasHastags && cookie === true ? <HashTags filter={ this.state.filter } data={ this.state } hashtags={ this.state.filter && this.state.filter.length ? this.state.filtertags : this.state.hashtags } onFilter={ (data) => { this.filter(data) } } onClose = { this.hashtagsONOFF } /> : '' }
						</div> 
						
						: false }
						
						{ (hasHastags && filter === true) && this.state.hashtagsOpen === false ?
							
						<div id="search">
							<button className={ this.state.filter && this.state.filter.length ? 'filter-off filtered' : 'filter-off' } onClick={ this.hashtagsONOFF }>Filter messages</button>
						</div>
						
						: false }
						
						{ cookie === true && page !== 'rules' && page !== 'cookies' && page !== 'terms' && page !== 'privacy' ?
							
							<Fragment>
								
								<Route render={ 
									(route) => { return (
										<Location onLocationChange = { this.locationChange } onMove = { this.move } onZoomChange = { this.zoomChange } onRefresh={ this.refresh } data={ this.state } posts={ this.posts } onPost={ this.postOpen }  redirect = { this.redirect } zoom={ zoom } location={ this.location } locked={ this.state.locked } route={ route } onStyleChange = { this.styleChange } />
									)}
								} />
								
								<section id="content">
									
									<Errors data={ this.state } error={ this.state.error } posts={ this.posts } errorUpdate={ this.errorUpdate } />
									
									{ statusAlert  ? 
										
										<div id="alert" className="autoclose">
											{ statusAlert }
											<p>{ statusAction ? statusAction : <button onClick={ this.closeAlert }>Close</button> }</p>
										</div>
								
									: false }
									
									{ cookie === true ?
										
									<Switch>
										
										<Route path="/" exact render={ (route) => <Index posts={ this.posts } data={ this.state } location={ this.location } onFilter={ this.filter } /> } />
										
										<Route path="/at" render={ (route) => <Index posts={ this.posts } data={ this.state } location={ this.location } onFilter={ this.filter } /> } />
										
										<Route path="/explore" render={ (route) => <Index posts={ this.posts } data={ this.state } location={ this.location } onFilter={ this.filter } /> } />
										
										<Route path="/dashboard" exact render={ this.state.authorized === false ? (props) => <E404 data={ this.state.data } /> : (props) => <Dashboard data={ this.state } { ...props } /> } />
										<Route path="/reports" exact render={ this.state.authorized === false ? (props) => <E404 data={ this.state.data } /> : (props) => <Reports data={ this.state } { ...props } /> } />
										<Route path="/allposts" exact render={ this.state.authorized === false ? (props) => <E404 data={ this.state.data } /> : (props) => <Allposts data={ this.state } { ...props } /> } />
										
										<Route path="/users" exact render={ this.state.authorized === false ? (props) => <E404 data={ this.state.data } /> : (props) => <Users data={ this.state } { ...props } /> } />
										
										
										<Route path="/messages" render={ () => <Feed posts={ this.posts } data={ this.state } location={ this.location } onFilter={ this.filter } onRefresh={ this.manualRefresh } /> } />
										<Route path="/places" render={ () => <Feed posts={ this.posts } data={ this.state } location={ this.location } onFilter={ this.filter } onRefresh={ this.manualRefresh } /> } />
										<Route path="/location" render={ () => <Index posts={ this.posts } data={ this.state } location={ this.location } onFilter={ this.filter } /> } />
					      				
					      				<Route path="/add" render={ () => <New data={ this.state } post={ this.state.post } location = { this.location } path={ this.props.location } onCompose={ this.compose } onPublish={ this.publish } onStatusChange={ this.statusChange } /> } />
				
										<Route path="/register" render={ () => <Register data={ this.state.path } sessionInput={ this.sessionInput } session={ this.session } /> }  onPublish={ this.publish } onStatusChange={ this.statusChange } />
										
										<Route path="/password" render={ () => <Password data={ this.state.path } sessionInput={ this.sessionInput } session={ this.session } /> } />
										
										<Route path="/login/:hash" render={ (route) => <Login data={ this.state } sessionInput={ this.sessionInput } session={ this.session } onStatusChange={ this.statusChange } path={ route } /> } />
										
										<Route path="/login" render={ () => <Login data={ this.state } onStatusChange={ this.statusChange } sessionInput={ this.sessionInput } session={ this.session } /> } />
										
										<Route path="/message/:id" render={ (route) => <Post data={ this.state } location={ this.location } posts={ this.posts } session={ this.session } onPost={ this.postOpen } route={ route } onDialog = { this.dialog } /> } />
										
										<Route path="/edit/:id" render={ (route) => <Edit data={ this.state } location={ this.location } onCompose={ this.compose } onPublish={ this.publish } onStatusChange={ this.statusChange } path={ route } /> } />
										
										<Route path="/auth" render={() => <Auth data={ this.state } onStatusChange={ this.statusChange } sessionInput={ this.sessionInput } session={ this.session } />} />
										
										<Route path="/user" render={ () => <User data={ this.state } onStatusChange={ this.statusChange } /> } />
										
										<Route path="/logout" render={() => <Logout data={ this.state } onStatusChange={ this.statusChange } />} />
		
										
										<Route render={ () => <E404 data={ this.state.data } /> } />
										
									</Switch>
									
									: false }
									
								</section>
								
								
							</Fragment>
						
						: 
						
							<Fragment>
						
								{ page !== 'rules' && page !== 'cookies' && page !== 'terms' && page !== 'privacy' ?
				
									<div id="cookie-notif">
										<div className="step"><span>Step 2</span> of <span>2</span></div>
										<h1>We use cookies to make your experience better</h1>
										<p className="policy">Please review our <a className="button" href="/cookies">Cookie policy</a></p>
										<p>Cookies ensure a convenient service, like remembering you and your preferences. They also help us improve our platform by analyzing site usage.</p>
										<p className="actions"><a className="exit" href="https://atround.com">Cancel</a> 
											<button className="continue" onClick={ this.cookieAgree }>I agree. Continue</button></p>
			
									</div>
							
								: '' }
							
							</Fragment>
					
						}
					
					
					</Fragment>
					
				: '' }
				
				<Fragment>
					<Route path="/privacy" render={ () => <Privacy /> } />
					<Route path="/cookies" render={ () => <CookiePolicy /> } />
					<Route path="/terms" render={ () => <Terms /> } />
					<Route path="/rules" render={ () => <Rules data={ this.state } update={ this.updateTerms } /> } />
				</Fragment>
				
			</div>
		)
	}
}
